
.list-group-item{
    width: 75vw;
    text-align: left;
    background-color: #2B3035;
}
.list-group{
    margin-top: 3%;
    margin-left: 3%;
    margin-right: auto;
}
#toggle-button{
    width: 15vw;
}
.resume-section{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.title{
    font-size: medium;
    margin-top: 2%;
    margin-bottom: 3%;
    color: white;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}
@media only screen and (max-width: 700px) {
    .title{
        margin-top: 3%;
        margin-bottom: 5%;
    }
}
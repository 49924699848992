.about-me-page{
    background-color: #2B3035;
    color: rgb(255, 255, 255);
    height:100%;
    width:100%;
}
.contact-icon{
    height: 50px;
    width: 60px;
}
.contact-link{
    font-size: small;
    margin-left: 4%;
    margin-right: 15%;
    color: white;
}
.contact-email{
    font-size: small;
    margin-left: 4%;
    margin-right: 15%;
    color: white;
}
.headshot{
    margin-left: 30px;
    width: 300px;
    height: 320px;
    border-radius: 50%;
    float: left;
}
.intro-flexbox{
    margin-top: 8%;
    width: 90vw;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 3%;
    font-size: larger;
}
.sbu-image{
    width: 100px;
    height: 100px;
}
.title{
    font-size: x-large;
}
.image{
    height: 36vh;
    width: 25vh;
}
.header-flexbox{
    padding-left: 12%;
    width: 40vw;
    height: 15%;
    margin-left: 25%;
    margin-right: 25%;
    display: flex;
    margin-top: 3%;
    align-items: center;
    justify-content: center;
}
.footer-flexbox{
    width: 85vw;
    margin-top: 7%;
    margin-bottom: 1%;
    font-size: 14pt;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4%;
}
@media only screen and (max-width: 700px) {
    .header-flexbox {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .intro-flexbox{
        flex-direction: column;
        width: 100vw;
        padding-top: 4%;
        margin-top: 2%;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 6%;
        font-size: larger;
    }    
    .contact-icon{
        margin-right: auto;
        margin-left: auto;
        height: 40px;
        width: 40px;
        margin-bottom: 7%;
    }
    .contact-link{
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 15%;
    }
    .contact-email{
        padding-left: 12%;
        margin-bottom: 10%;
    }
    .headshot{
        width: 275px;
        height:300px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4%;
    }
    .intro-flexbox{
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-right: auto;
        margin-left: auto;
    }
    .sbu-image{
        margin-bottom: 25%;
    }
    .footer-flexbox{
        flex-direction: column;
    }
  }


.intro{
  margin-bottom: 6%;
  margin-top: 1%; 
  margin-right: auto; 
  margin-left: auto;
  width: 75%;
  color: white; 
  font-size: 12pt;
}
  .flex{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 3%;
}
@media only screen and (max-width: 700px) {
  .intro{
    margin-top: 3%;
    margin-bottom: 9%;
  }
  .flex{
    flex-direction: column;
    align-items: center;
  }
  div.polaroid{
    width: 65%;
    margin-top: 3%;
    padding-top: 3%;
  }

}
  .polaroid {
    width: 25%;
    height:33%;
    padding-top: 1%;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 100px;
    display: absolute;
  }
  
  div.container {
    height: 5%;
    text-align: center;
    padding: 10px 20px;
    height: 10%;
    margin-bottom: 15px;
  }

.image{
    height: 85%;
    width: 75%;
  }
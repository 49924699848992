.App {
  text-align: center;
  width: 100%;
  font-family: 'Gill Sans LT', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  background-color: #2B3035;
  margin: 0px;
  padding: 0px;
  background-size: 100vw 100vw;
  background-repeat: no-repeat;
}

 
.body{
    font-size: 14pt;
    color: rgb(255, 255, 255);
    height: 100vh;
    background-image: url('../images/skyline.jpeg');
    background-repeat: no-repeat;
    background-size: cover ;
    background-position: center center;
}
.body-text{
    height: 15%;
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
    font-size: 25pt;
    font-weight: bolder;
}
@media only screen and (max-width: 700px) {
    .body-text{
        font-size: 10pt;
    }
}
